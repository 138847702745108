*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Fredoka One', cursive;
    letter-spacing: 1.1px;
}

html, 
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden; 
}

.home{
    position: relative;
}

.nav{
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 15px 0;
    font-size: 1.5rem;
    top: 0;
    right: 0;
    color: white;
    width: 100%;
    z-index: 5;
}

.nav-options{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
    
}

.nav-link{
    cursor: pointer;
}

.flag{
    cursor: pointer;
    height: 25px;
    align-self: center;
    justify-self: center;
}

.hamburger {
    display: none;
    width: 3.6rem;
    height: 1.8rem;
    position: fixed;
    right: 40px;
    top: 40px;
    cursor: pointer;
    line-height: 5rem;
    z-index: 1500;
  }
  
  .hamburger .line {
    width: 100%;
    height: 6px;
    background-color: white;
    position: absolute;
    display: block;
    right: 0;
    transition: all 500ms;
  }

  .line{
      border: 1px solid rgba(0, 0, 0, 0.274);
      border-radius: 15px;
  }
  
  .hamburger .line:nth-child(1) {
    top: 0;
  }
  
  .hamburger .line:nth-child(2) {
    top: 50%;
    margin-top: -1px;
    width: 2.8rem;
  }
  
  .hamburger .line:nth-child(3) {
    top: 100%;
    margin-top: -2px;
    width: 2rem;
  }
  
  .hamburger.active .line:nth-child(1) {
    transform: translateY(.8rem) rotate(45deg);
    width: 2rem;
  }
  
  .hamburger.active .line:nth-child(2) {
    opacity: 0;
  }
  
  .hamburger.active .line:nth-child(3) {
    transform: translateY(-.8rem) rotate(-45deg);
    width: 2rem;
  }

@media(max-width:750px){
    .nav-name{
        display: none;
    }
    .hamburger{
        display: block;
    }
    .nav{
        background: rgba(0, 0, 0, 0.288);
        position: fixed;
        display: flex;
        margin: 0;
        right: 0;
        flex-direction: column;
        justify-content: center;
        width: 45vw;
        height: 100vh;
        z-index: 50;
        transform: translate(100%);
        transition: transform .500s ease;
    }

    .nav-options{
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 50px;

    }
}

/* ===== Hero Section ====== */

.home-hero{
position: relative;
  background-image: linear-gradient(to bottom, #4d6199, #5870a6, #6480b2, #718fbf, #7f9fcb, #89aad3, #94b4dc, #9fbfe4, #a8c8ea, #b2d0f0, #bcd9f7, #c6e2fd);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
  width: 100%;
  color: white;
}

.hero-body{
    width: 90%;
    height: 80%;
    display: grid;
    grid-template-columns: repeat(12, minmax(100px, 200px));
    grid-template-rows: repeat(6, minmax(100px, 400px));
}

.hero-text{
    grid-column: 2/-1;
    grid-row: 3;

}

.hero-text-subtitle-container{
    display: flex;
    flex-direction: row;
    gap: 15px;
}
.hero-text-title{
    font-size: 2.4rem;
}

.hero-img{
    height: 400px;
    grid-column: 8/-1;
    grid-row: 2;
}

.hero-btn-container{
    margin-top: -88px;
    margin-right: -135px;
    grid-row: 5;
    grid-column: 5/7;
    display: block;
    display: flex;    
    justify-content: center;
    align-items: center;
}

.hero-btn-anchor{
    position: relative;
    right: calc(50% - 250px);
}

.hero-btn{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: rgba(70, 165, 243, 0.986);
    height: 50px;
    width: 150px;
    border-radius: 15px;
    border: none;
    background-color: white;
    z-index: 10000;
    transition: transform 300ms ease;
}

.hero-btn:hover{
    transform: scale(1.1);
}

.btn-floating{
    color: white;
    position: fixed;
    right: 40px;
    top: 90%;
    background: rgba(22, 45, 122, 0.836);
    border: 2px solid white;
    border-radius: 50%;
    height: 65px;
    width: 65px;
    animation-name: contactBtn;
    animation-duration: 800ms;
}

@keyframes contactBtn {
    0%   {background:white;
        width: 150px;
        border: 0px;
        border-radius: 15px;
        top:100px; right:50%;}
    30%{background: white}
    50%{background:rgba(22, 45, 122, 0.836);}
    100% {top:90%; right:40px;}
}

.hero-btn:focus{
    outline: none;
}

@media(max-width:1200px){
    .hero-img{
        grid-column: 6/-1;
        grid-row: 2;
    }
}

@media(max-width:1320px){
    .hero-img{
        display: none;
    }
    
    .hero-btn-container{
        grid-column: 3/5;
    }
}
@media(max-width:750px){
    .hero-text{
        grid-column: 1/-1;
        grid-row: 2;
    }
    .hero-text-title{
        font-size: 2rem;
    }

    .hero-btn-anchor{
        right: calc(50% - 150px);
    }
    .hero-btn-container{
        display: block;
        height: 200px;
        display: flex;    
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        left: calc(50% - 150px);
    }
}

@media(max-width:450px){
    .hero-text{
        grid-column: 1/-1;
        grid-row: 2;
        max-width: 350px;
    }
}

@media(max-width:350px){
    .hero-text{
        grid-column: 1/-1;
        grid-row: 2;
        max-width: 300px;
    }
}

@media(max-height:580px){
    .hero-text{
        margin-top: -80px;
    }
}
.waves{
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.wave3{
    width: 100vw;
}

/* ==== Container  ====*/

.main-grid{
    margin-top: -50px;
    display: grid;
    grid-template-columns: minmax(1em, 1fr) repeat(2, minmax(50px, 500px)) minmax(1em, 1fr);
    column-gap: 1em;
}

.body-title-container{
    grid-column: 2/-2;
    align-self: center;
    justify-self: center;
    text-align: center;
    font-size: 1.8rem;
  color:rgba(39, 48, 173, 0.438);
}

/* === Card === */
.body-decoration{
    position: absolute;
    right: -10px;
    top: -80px;
    height:80px;
    opacity: .5;
}

.body-decoration2{
    position: absolute;
    height: 80px;
    transform: rotate(-150deg);
    opacity: .6;
    left: 30px;
    z-index: -2;
    top: 480px;
}


.card-container{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
    grid-column: 2/-2;
    height: auto;
    margin-bottom: 30px;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.body-card-img,.body-card-img-cellphone{
     order: 4;
    height: 250px;
    opacity: .8;
    object-fit: cover;
    padding: 10px;
    border-radius: 15px;
}

.body-img-robot{
    order: 1;
    height: 150px;
    object-fit: contain;
    opacity: .8;
}
.body-card-img-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 450px;
    width: 400px;
}

.body-card-img-pivot{
    height: 50%;
    opacity: .8;
    object-fit: cover;
    padding: 10px;
    border-radius: 15px;
}


.card{
    cursor: pointer;
    background-color: white;
    height: 450px;
    width: 400px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    -webkit-box-shadow: 4px 4px 7px 0px rgba(50, 50, 50, 0.64);
    -moz-box-shadow:    4px 4px 7px 0px rgba(50, 50, 50, 0.64);
    box-shadow:         4px 4px 7px 0px rgba(50, 50, 50, 0.64);
}

.card:hover .card-img{
    transform: scale(1.1) ;
}

.card-img-container{
    border: 1px solid rgba(255, 255, 255, 0.479);
    height: 50%;
    margin: 10px;
    border-radius: 15px;
    overflow: hidden;
}

.card-img{
    object-fit: cover;
    height: 100%;
    transition: transform .300s ease;
    border-radius: 15px;
}

.card-body{
    height: 50%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    text-align: center;
}

.card-title, .card-body-text, .card-body-extra{
    font-family: 'Tajawal', sans-serif;
}

.card-title{
    font-size: 1.4rem;
}

.card-body-text, .card-body-extra{
    font-size: 1.2rem;
    margin-bottom: 15px;
}

@media(max-width: 950px){
    .body-card-img-container{
        display: none;
    }
}

@media(max-width: 750px){
    .card{
        width: 300px;
        height: 500px;
    }
}

/* === Skills container === */

.skills-container,.about-container{
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    background-color: #060d30;
}



.title-light{
    color: aliceblue;
    letter-spacing: 8px;
}
.skills-wrapper{
    display: flex;
    align-items: center;

    flex-direction: column;
}

.skills-images-container{
    justify-content: center;
    display: flex;
    flex-direction: row;
    gap: 40px;
}

.skill-item-container{
    margin: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 50px;
    width: 900px;
    flex-wrap: wrap;
}

@media(max-width: 950px){
    .skill-item-container{
        width: 500px;
    }

    .skills-images-container{
        flex-wrap: wrap;
        width: 500px;
    }
}

@media(max-width: 750px){
    .skill-item-container{
        width: 400px;
    }

    .skills-images-container{
        flex-wrap: wrap;
        width: 400px;
    }
}

.skill-item{
    text-decoration:dotted;
    font-size: 1.8rem;
}



.logo-icon{
    height: 80px;
}

.about-container{
    color: rgb(243, 243, 247);
    position: relative;
    height: 600px;
    justify-content: center;
}



.about-info{
    position: relative;
    width: 700px;
    height: auto;
    padding: 10px;
    /* background: rgba(0, 0, 0, 0.205); */
    z-index: 3;
}

.about-info h3{
    font-size: 2rem;
    color: rgb(255, 255, 255);
}

.about-info p{
    font-size: 1.6rem;
    font-family: 'Tajawal', sans-serif;
}

.background-image{
    position: absolute;
    top: -50px;
    left: -470px;
    height: 800px;
    z-index: -1;
    opacity: .5;
}

.img-code{
    position: absolute;
    height: 300px;
    right: -400px;
}

@media(max-width:1600px){
    .img-code{
        position: absolute;
        height: 300px;
        right: -300px;
    }
}

@media(max-width:1300px){
    .img-code{
        display: none;
    }
}

@media(max-width:750px){
    .about-info{
        margin-top: 100px;
        width: 350px;
        color: white;
        background: rgba(0, 0, 0, 0.473);
        border-radius: 15px;
    }

    .logo-icon{
        height: 50px;
    }
}

/* == Contact == */
.contact-container{
    position: relative;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 750px;
}

.contact-wrapper{
    z-index: 10;
}

.contact-title{
    color: rgb(35, 7, 80);
    font-size: 3rem;
}

.contact-inputs{
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.contact-input{
    height: 40px;
    border-radius: 5px;
    border: 1px solid black;
}
.contact-areatext{
    resize: none;
    height: 100px;
}

.contact-areatext, .contact-input{
    font-size: 1rem;
    width: 350px;
    padding: 10px;
}
.btn{
    height: 40px;
    cursor: pointer;
    width: 200px;
    align-self: center;
    border-radius: 10px;
    border: none;
    background-color: #beb3fd42;
    transition: all .250s ease;
} 

.btn:focus{
    outline: none;
}

.btn:hover{
    background-color: #5ecece38;
    transform: scale(1.1);
}
.contact-img{
    position: absolute;
    height: 400px;
    right: -650px;
    opacity: .8;
}

@media(max-width:1700px){
    .contact-img{
        position: absolute;
        height: 350px;
        right: -470px;
        opacity: .8;
    }
}

@media(max-width:1320px){
    .contact-img{
        display: none;
    }
}

@media(max-width:750px){
    .contact-areatext, .contact-input{
        width: 300px;
    }
}


.contact-img2{
    position: absolute;
    height: 200px;
    bottom: 100px;
    left: -450px;
    opacity: .8;
}

/* === modal message === */

.modal-message{
    position: absolute;
    z-index: 30;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.514);
}

.dialog-message{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.messageDialog-img{
    height: 100px;
    align-self: center;
    justify-self: center;
    margin-bottom: 10px;
}

.dialog-message-content{
    display: flex;
    align-items: center;
}

#alert-dialog-slide-description{
    font-size: 1.3rem;
}

.reCaptcha-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}


.footer{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content:center;
    align-items: center;
    height: 100px;
    gap: 10px;
    background-color: #060d30;
}
.footer-title{
    color: white;
    font-family: 'Tajawal', sans-serif;
}
.linkedin-icon{
    cursor: pointer;
    height: 28px;
    fill: white;
    width: 30px;
}